import { createSlice } from '@reduxjs/toolkit'
const updateLeadSlice = createSlice({
    name: "updateLead",
    initialState: {
      updateLead: {},
      updateLoad: false,
      updateerror: false,
      callback_value: [],
      reschedule_value: [],
      dropped_value: [],
      GCLCallDuration_value: [],
      followUp_value: [],
      transport_value: [],
      getExpenseType: [],
      vehical_value: [],
      meeting_value:[],
      lead_action_list:[],
      isUpdated:false,
      meetingStatus: "",
      AssignedTo:[],
      lead_history:[],
      lead_Details_Table:[],
      Persistancylead_Details_Table:[],
      Payment_Type:[],
      Payment_Sub_Type:[],
      PersistancyLeadSubStatus_value:[],
      PersistancyLeadModeOfPayment_value:[],
      PersistencyDeferredReasons_value:[],
      PersistencyWillNotPayReasons_value:[]

    },
    reducers: {
      updateLeadLoading: state => {
        state.updateLoad = true;
      },
      updateLeadSuccess: (state, action) => {
        state.updateLead = action.payload;
      },
      updateLeadError: (state, action) => {
        state.updateerror = action.payload;
        state.updateLoad = false;
      },
      getCallBackReason:(state,action)=>{
         state.callback_value = action.payload;
      },
       getReschduleReason:(state,action)=>{
         state.reschedule_value = action.payload;
      },
      getPersistancyLeadSubStatus:(state,action)=>{
        state.PersistancyLeadSubStatus_value = action.payload;
     },
     getPersistancyLeadModeOfPayment:(state,action)=>{
      state.PersistancyLeadModeOfPayment_value = action.payload;
     },
     getPersistencyDeferredReasons:(state,action)=>{
     state.PersistencyDeferredReasons_value = action.payload;
     }, 
     getPersistencyWillNotPayReasons:(state,action)=>{
      state.PersistencyWillNotPayReasons_value = action.payload;
     },
      getDroppedReason:(state,action)=>{
         state.dropped_value = action.payload;
      },
       getGCLCallDuration:(state,action)=>{
         state.GCLCallDuration_value = action.payload;
      },
      getFollowUpReason:(state,action)=>{
        state.followUp_value = action.payload;
      },
      getMeeting:(state,action)=>{
        state.meeting_value = action.payload;
      },
      getTransport:(state,action)=>{
        state.transport_value = action.payload;
      },
      getExpenseType:(state,action)=>{
        state.expense_value = action.payload;
      },
      getVehicalType:(state,action)=>{
        state.vehical_value = action.payload;
      },
      getLeadAction:(state,action)=>{
        state.lead_action_list = action.payload;
      },
      updateLeadStatusAction:(state,action)=>{
        state.isUpdated = action.payload;
      },
      getTeam:(state,action)=>{
        state.loading=false;
        state.AssignedTo = action.payload;
      },
      getMeetingStatusInUpdate: (state, action) => {
        state.loading = false;
        state.meetingStatus = action.payload
      },
      getLeadDetailsHistory:(state,action)=>{
        state.lead_history = action.payload;
      },
      getLeadDetailsForTable:(state,action)=>{
        state.lead_Details_Table = action.payload;
      },
      getPersistancyLeadDetailsForTable:(state,action)=>{
        state.Persistancylead_Details_Table = action.payload;
      },
      getPaymentType:(state,action)=>{
        state.Payment_Type = action.payload;
      },
      getPaymentSubType:(state,action)=>{
        state.Payment_Sub_Type = action.payload;
      },
    }
  });

export const { updateLeadLoading,updateLeadError,updateLeadSuccess,getVehicalType,getExpenseType,getTransport,
  getFollowUpReason,getDroppedReason,getGCLCallDuration,getReschduleReason,getCallBackReason,getMeeting,getLeadAction,updateLeadStatusAction,
  getTeam,getLeadDetailsHistory,getLeadDetailsForTable,getMeetingStatusInUpdate,getPaymentType,getPaymentSubType,
  getPersistancyLeadDetailsForTable,getPersistancyLeadSubStatus,getPersistancyLeadModeOfPayment,getPersistencyDeferredReasons,getPersistencyWillNotPayReasons} = updateLeadSlice.actions
export default updateLeadSlice.reducer



