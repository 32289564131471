import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import "./../../leadlist.scss";
import { useDispatch, useSelector } from "react-redux";
import { getLeadStatusList } from "../../../redux-reducer/reducer";
import PersistancyLeadFilter from "./persistencyLeadDatagridFilter";
import useOnlineStatus from "../../../hooks/useOnlineStatus";
import { setPersistanyLeadsOverview } from "../../../redux-reducer/reducer/leadListReducer";
import { connect } from "react-redux";
import {
  downloadPersistencyLeads,
  downloadPersistencyLeadsWithIds,
  getPersistancyLeadListing,
  deActivateLoader,
  activateLoader,
} from "../../../redux/actions/common";
import { isBrowser, isMobile } from "react-device-detect";
import ModalPopup from "f1-modal-react";
import MatDatagrid from "f1-mat-data-grid-react";
import { downloadFilesAdmin } from "../../../utils/utils";
import DataGridModalPopup from "./../../datagridpopup";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Paginator } from "./../../paginator";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const currentStatusId = "1,2,3,4,5,6,7,8,9,10";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function LeadListingPerSistancyDatagrid(
  props
) {
  const dispatch = useDispatch();
  const isOnline = useOnlineStatus();
  const commonState = useSelector((state) => state);
  const userState = commonState.userReducer;
  const formRef = useRef();
  const { getPersistancyLeadsOverView } = useSelector(
    (leadreducer) => leadreducer.leadListReducer
  );
  const chanelId = props.ChannelId;
  const userData = JSON.parse(localStorage.getItem("persist:root"));
  // const userId = JSON.parse(userData.userReducer).profileInfo.userId;
  const roleId = JSON.parse(userData.userReducer).profileInfo.roleId;
  const [showModalPopup, setShowModalPopup] = useState(false);
  // const [open, setOpen] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const history = useHistory();
  const [stateSelect, setStateSelect] = React.useState({
    bottom: false,
  });
  // const [selectedFilterPersistency, setSelectedFilterPersistency] = useState({
  //   roleId: roleId,
  //   viewFor: "",
  // });

  const [NoOfRecordperPage, setNoOfRecordperPage] = useState(100);
  const [value] = React.useState(0);
  const [showModal] = useState(true);
  const [persistencyLeadForGrid, setPersistencyLeadForGrid] = useState([]);
  let [rowPerPage, setRowPerPage] = useState(20);
  let [rowChangeEvent, setRowChangeEvent] = useState(0);
  let [totalCount, setTotalCount] = useState(0);
  let [initialPageNo, setInitialPageNo] = useState(0);

  const [leadFilterState, setLeadFilterState] = useState({
    UserID: null,
    LeadName: null,
    CreatedDateTime: null,
    CreatedDateStartTIme: "null",
    CreatedDateEndTIme: "null",
    MeetingOn: null,
    persistancycurrentStatus: [],
    LastUpdateDateTime: null,
    currentStatus: null,
    product: 0,
    activityID: null,
    MeetingDate: null,
    fromdate: "null",
    todate: "null",
    MobileNo: null,
    campaign: 0,
    EmployeeCode:null,
  });

  const columns = [
    {
      title: "Created Date ",
      field: "CreatedDate",
      type: "date",
      sorting: true,
      render: (rowData) => moment(rowData.CreatedDate).format("DD/MM/YYYY"),
    },
    {
      title: "Meeting Date ",
      field: "MeetingDate",
      type: "date",
      sorting: true,
    },
    { title: "Customer Name", field: "CustomerName", sorting: true },
    { title: "Current Status", field: "CurrenStatus", sorting: true },
    { title: "Assigned To", field: "Assignedto", sorting: true },
    {
      title: "Last Updated date ",
      field: "LastUpdatedDate",
      type: "date",
      sorting: true,
    },
    { title: "Activity Name", field: "ActivityID", sorting: true },
  ];

  const csvExp = async () => {
    console.log("sel row data", selectedRows);
    const IDs = [];
    if (selectedRows.length > 0) {
      const rows = selectedRows;
      for (const key in rows) {
        console.log("rows data is", rows[key].LeadID);
        IDs.push(rows[key].LeadID);
      }
      let ids = IDs.map((id) => id).join(",");
      console.log("idss", ids);
      let storedIds = ids.toString();
      try
      {
        props.activateLoader();
        const downloadRes = await props.downloadPersistencyLeadsWithIds({
          ChannelId: chanelId,
          ids: storedIds,
        });
        console.log("ids apis res is", downloadRes);
        downloadFilesAdmin(downloadRes, props.fileName);
        props.deActivateLoader();
      }
      catch(err)
      {
        props.deActivateLoader();
      }
    } else {
      const payload = {
        ChannelId: props.ChannelId,
        filterReq: {
          ...leadReq,
        },
      };

      try {
        props.activateLoader();
        const downloadData = await props.downloadPersistencyLeads(
          { ChannelId: payload.ChannelId },
          payload.filterReq
        );
        if (downloadData.status === 200) {
          props.deActivateLoader();
          downloadFilesAdmin(downloadData, props.fileName);
        } else {
          props.deActivateLoader();
        }
      } catch (err) {
        props.deActivateLoader();
      }
    }
  };

  const isShowPopup = (status) => {
    setShowModalPopup(status);
    if (successMsg !== "") {
      // setOpen(false);
      setSuccessMsg("");
    }
  };

  const rowOnRowclick = (e, row) => {
    const leadId = row.LeadID;
    console.log("row clicked ", row);
    if(userState.profileInfo.roleId ==='ADM')
    {
    history.push(`/app/PersistencyLead/details/${leadId}`);
    }
    else{
      history.push(`/app/persistencyLead/detail/${leadId}`);
    }
  };

  const [leadReq, setLeadReq] = useState({
    CreateStartDate: null,
    CreateEndDate: null,
    MeetingStartDate: null,
    MeetingEndDate: null,
    CustomerName: null,
    CurrentStatus: "1,2,3,4,5,6,7,8,9,10,14,25,26,27",
    ActivityId: null,
    UserId: null,
    CampaignName: null,
    ProductId: null,
    MobileNumber: null,
    EmployeeCode:null,
  });

  //const [isLeadFilterApplied, setLeadFilterApplied] = useState(false);
  const scrollToTop = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const getLeadByOverviewSelection = (type) => {
    switch (type) {
      case "open":
        return {
          ChannelId: props.ChannelId,
          Page: 1,
          rowPerPage: rowPerPage,
          filterReq: { ...leadReq, CurrentStatus: "2,3,4,7" },
        };
      case "un-actioned":
        return {
          ChannelId: props.ChannelId,
          Page: 1,
          rowPerPage: rowPerPage,
          filterReq: { ...leadReq, CurrentStatus: "1" },
        };
      case "collected":
        return {
          ChannelId: props.ChannelId,
          Page: 1,
          rowPerPage: rowPerPage,
          filterReq: { ...leadReq, CurrentStatus: "14" },
        };
      case "dropped":
        return {
          ChannelId: props.ChannelId,
          Page: 1,
          rowPerPage: rowPerPage,
          filterReq: { ...leadReq, CurrentStatus: "5" },
        };
      case "all":
        return {
          ChannelId: props.ChannelId,
          Page: 1,
          rowPerPage: rowPerPage,
          filterReq: { ...leadReq },
        };
      default:
        break;
    }
  };
  const fetchPersistencyLead = async (
    ChannelId,
    Page,
    rowPerPageCount,
    filterReq
  ) => {
    if (isOnline) {
      props.activateLoader();
      const result = await props.getPersistancyLeadListing(
        ChannelId,
        Page,
        rowPerPageCount,
        filterReq
      );
      if (result.status === 200) {
        var responseData = [];
        for (let i = 0; i < result.data.Getallpersistencyleadlist.length; i++) {
          if (result.data.Getallpersistencyleadlist[i].MeetingDate !== null) {
            result.data.Getallpersistencyleadlist[i].MeetingDate = moment(
              result.data.Getallpersistencyleadlist[i].MeetingDate
            ).format("DD/MM/YYYY");
          }
          if (
            result.data.Getallpersistencyleadlist[i].LastUpdatedDate !== null
          ) {
            result.data.Getallpersistencyleadlist[i].LastUpdatedDate = moment(
              result.data.Getallpersistencyleadlist[i].LastUpdatedDate
            ).format("DD/MM/YYYY");
          }
          responseData.push(result.data.Getallpersistencyleadlist[i]);
        }
        props.deActivateLoader();
        setPersistencyLeadForGrid(responseData);
        setTotalCount(result.data.TotalnoofRows);
        console.log(
          "result.data.Getallcampaignactivitieslistbychannel ",
          result.data.Getallpersistencyleadlist
        );
      } else {
        props.deActivateLoader();
      }
    }
  };
  useEffect(() => {
    if (isOnline) {
      const payload = getLeadByOverviewSelection(getPersistancyLeadsOverView);

      setLeadReq({
        ...leadReq,
        CurrentStatus: payload.filterReq.CurrentStatus,
      });
      if (userState.profileInfo.roleId !=='ADM') {
        payload.filterReq.UserId = userState.profileInfo.userId
        setLeadReq({
          ...leadReq,
          UserId : userState.profileInfo.userId
        });
      }
      fetchPersistencyLead(
        payload.ChannelId,
        payload.Page,
        payload.rowPerPage,
        payload.filterReq
      );
    }
    return () => {
      // Anything in here is fired on component unmount.
      dispatch(setPersistanyLeadsOverview("all"));
    };
  }, []);

  useEffect(() => {
    if (isOnline) {
      dispatch(getLeadStatusList());
    }
  }, []);
  const handleChangeRowPer = async (e) => {
    const payload = {
      ChannelId: props.ChannelId,
      Page: 1,
      filterReq: {
        ...leadReq,
      },
    };
    setRowPerPage(e.target.value);
    if (isOnline) {
      props.activateLoader();
      const result = await props.getPersistancyLeadListing(
        payload.ChannelId,
        payload.Page,
        e.target.value,
        payload.filterReq
      );
      if (result.status === 200) {
        var responseData = [];
        for (let i = 0; i < result.data.Getallpersistencyleadlist.length; i++) {
          if (result.data.Getallpersistencyleadlist[i].MeetingDate !== null) {
            result.data.Getallpersistencyleadlist[i].MeetingDate = moment(
              result.data.Getallpersistencyleadlist[i].MeetingDate
            ).format("DD/MM/YYYY");
          }
          if (
            result.data.Getallpersistencyleadlist[i].LastUpdatedDate !== null
          ) {
            result.data.Getallpersistencyleadlist[i].LastUpdatedDate = moment(
              result.data.Getallpersistencyleadlist[i].LastUpdatedDate
            ).format("DD/MM/YYYY");
          }
          responseData.push(result.data.Getallpersistencyleadlist[i]);
        }
        props.deActivateLoader();
        setPersistencyLeadForGrid(responseData);
        setRowChangeEvent(e.target.value);
        setTotalCount(result.data.TotalnoofRows);
        setNoOfRecordperPage(NoOfRecordperPage + 10);
        setSelectedRows([]);
      } else {
        props.deActivateLoader();
      }
    }
  };

  const onChildMount = React.useCallback((dataFromChild) => {
    setInitialPageNo(dataFromChild);
  });
  const onChangePage = async (e, page) => {
    const payload = {
      ChannelId: props.ChannelId,
      filterReq: {
        ...leadReq,
      },
    };
    if (isOnline) {
      props.activateLoader();
      const result = await props.getPersistancyLeadListing(
        payload.ChannelId,
        page + 1,
        rowPerPage,
        payload.filterReq
      );
      if (result.status === 200) {
        var responseData = [];
        for (let i = 0; i < result.data.Getallpersistencyleadlist.length; i++) {
          if (result.data.Getallpersistencyleadlist[i].MeetingDate !== null) {
            result.data.Getallpersistencyleadlist[i].MeetingDate = moment(
              result.data.Getallpersistencyleadlist[i].MeetingDate
            ).format("DD/MM/YYYY");
          }
          if (
            result.data.Getallpersistencyleadlist[i].LastUpdatedDate !== null
          ) {
            result.data.Getallpersistencyleadlist[i].LastUpdatedDate = moment(
              result.data.Getallpersistencyleadlist[i].LastUpdatedDate
            ).format("DD/MM/YYYY");
          }
          responseData.push(result.data.Getallpersistencyleadlist[i]);
        }
        props.deActivateLoader();
        setPersistencyLeadForGrid(responseData);
        setTotalCount(result.data.TotalnoofRows);
        setNoOfRecordperPage(NoOfRecordperPage + 10);
        setSelectedRows([]);
      } else {
        props.deActivateLoader();
      }
    }
  };

  const getFilteredLeads = async (filterRequest) => {
    //setLeadFilterApplied(true);
    const {
      CreatedDateEndTIme,
      LeadName,
      CreatedDateStartTIme,
      currentStatus,
      product,
      activityID,
      fromdate,
      todate,
      MobileNo,
      EmployeeCode,
      DueStartDate,
      DueEndDate,
      BranchId
    } = filterRequest;
    scrollToTop();
    const req = {
      UserId: (filterRequest.UserID !== null)  ? filterRequest.UserID : (userState.profileInfo.roleId !== 'ADM' ? userState.profileInfo.userId: null),
      CreateStartDate:
        CreatedDateStartTIme !== "null" ? CreatedDateStartTIme : null,
      CreateEndDate: CreatedDateEndTIme !== "null" ? CreatedDateEndTIme : null,
      MeetingStartDate: fromdate !== "null" ? fromdate : null,
      MeetingEndDate: todate !== "null" ? todate : null,
      DueStartDate: DueStartDate !== "null" ? DueStartDate : null,
      DueEndDate: DueEndDate !== "null" ? DueEndDate : null,
      BranchId: BranchId !== "null" ? BranchId : null,
      CustomerName: LeadName,
      CurrentStatus:
        currentStatus === 0 ||
        currentStatus === null
          ? currentStatusId
          : currentStatus,
      ActivityId: activityID,
      ProductId: product === 0 ? null : parseInt(product),
      MobileNumber: MobileNo,
      EmployeeCode:EmployeeCode,
    };
    setLeadReq({ ...req });
    if (isOnline) {
      fetchPersistencyLead(props.ChannelId, 1, 20, req);
      setRowPerPage(20);
      setNoOfRecordperPage(100);
      setSelectedRows([]);
    }
  };

  const getLeadStatusId = (type) => {
    switch (type) {
      case "open":
        return "2,3,4,7";
      case "un-actioned":
        return "1";
      case "collected":
        return "14";
      case "dropped":
        return "5";
      case "all":
        return "1,2,3,4,5,6,7,8,9,10,14,25";
      default:
        break;
    }
  };
  const pageReset = () => {
    setInitialPageNo(0);
  };
  const getAllPersistancyLeads = () => {
    scrollToTop();
    const payload = {
      ChannelId: props.ChannelId,
      Page: 1,
      rowPerPage: 20,
      filterReq: {
        CreateStartDate: null,
        CreateEndDate: null,
        MeetingStartDate: null,
        MeetingEndDate: null,
        CustomerName: null,
        CurrentStatus: getLeadStatusId(getPersistancyLeadsOverView),
        ActivityId: null,
        UserId: null,
        CampaignName: null,
        ProductId: null,
        MobileNumber: null,
        EmployeeCode:null,
        DueStartDate:null,
        DueEndDate:null,
        BranchId:null
      },
    };
    if (userState.profileInfo.roleId !=='ADM') {
      payload.filterReq.UserId = userState.profileInfo.userId
      setLeadReq({
        ...leadReq,
        UserId : userState.profileInfo.userId
      });

    }
    setLeadReq({ ...payload.filterReq });
    setRowPerPage(20);
    pageReset();
    setNoOfRecordperPage(100);
    setSelectedRows([]);
    if (isOnline) {
      fetchPersistencyLead(
        payload.ChannelId,
        payload.Page,
        payload.rowPerPage,
        payload.filterReq
      );
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setStateSelect({ ...stateSelect, [anchor]: open });
  };
  const handleLeadFilterState = (item) => {
    setLeadFilterState({
      ...item,
    });
  };

  return (
    <>
      {isBrowser && (
        <TabPanel value={value} index={0}>
          <div ref={formRef}>
            <Grid
              className="activiPad0"
              container
              spacing={2}
              columns={{ xs: 4, md: 12 }}
            >
              <Grid item xs={12} md={9} className="leftCol">
                <div className="admindashCompain">
                  <div className="float-right">
                    <button className="btn btn-primary" onClick={csvExp}>
                      <span className="fa fa-download">&nbsp;</span> Download
                      List
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    {roleId === 'ADM' &&
                    <button
                      className="btn btn-primary"
                      onClick={() => isShowPopup(true)}
                    >
                      <span className="fa fa-upload">&nbsp;</span> Bulk Upload
                    </button>
                    }
                  </div>
                  <div className="clearfix"></div>
                  <DataGridModalPopup
                    showModalPopup={showModalPopup}
                    onPopupClose={isShowPopup}
                    persistencyid={props.persistencyid}
                    filetype={props.filetype}
                  ></DataGridModalPopup>
                  <MatDatagrid
                    rows={persistencyLeadForGrid ? persistencyLeadForGrid : []}
                    columns={columns}
                    props={{ labelRowsSelect: "records" }}
                    search={false}
                    grouping={false} //to show or hide grouping
                    addRowPosition={"first"} //"last"
                    onSelectionChange={(rows) => setSelectedRows(rows)}
                    treeView={false}
                    selection={true}
                    filtering={false}
                    title={props.Listingtitle}
                    showFirstLastPageButtons={false}
                    pageSize={rowPerPage}
                    paging={false}
                    pageSizeOptions={[15, 20, 25, 50]}
                    paginationType={"normal"} //"normal" or "stepped"
                    exportButton={false}
                    exportAllData={true}
                    onRowClick={rowOnRowclick}
                    customPagination={{
                      isDisplay: true,
                      totalCount: totalCount,
                    }}
                    editable={{
                      showDelete: false,
                    }}
                    localization={{
                      body: {
                        emptyDataSourceMessage: " ",
                      },
                    }}
                  />
                </div>

                <div className="float-right">
                  {totalCount && totalCount > 9 ? (
                    <>
                      <label for="selectRecordsPerPage">
                        Records per Page: &nbsp;{" "}
                      </label>
                      <Select
                        value={rowPerPage}
                        onChange={(e) => handleChangeRowPer(e)}
                      >
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="clearfix">&nbsp;</div>
                {totalCount != 0 ? (
                  <Paginator
                    onMount={onChildMount}
                    rowCount={rowChangeEvent}
                    className="pagination"
                    totalRecoards={totalCount}
                    recordsPerPage={rowPerPage}
                    onPageChange={onChangePage}
                    maxPageLimit={10}
                    initialPage={initialPageNo}
                  />
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} md={3} className="rightCol">
                <PersistancyLeadFilter
                   setLeadReq={setLeadReq}
                   leadReq={leadReq}
                  filterState={leadFilterState}
                  setFilterState={handleLeadFilterState}
                  getFilteredLeads={getFilteredLeads}
                  getAllLeads={getAllPersistancyLeads}
                  parent={"sidebar"}
                  handleClose={toggleDrawer(stateSelect.bottom, false)}
                  //setFilterDisabled={() => setLeadFilterApplied(false)}
                  //setSelectedFilter={setSelectedFilterPersistency}
                  chanelId={chanelId}
                />
              </Grid>
            </Grid>
          </div>
        </TabPanel>
      )}
      {isMobile && (
        <ModalPopup
          show={showModal}
          size={"sm"} // "lg","sm" or "xl"
          centered={true} // vertically center the Dialog in the window
          modelHeader={"Invalid Selection"} // to show model header
          modelContent={
            <div className="footerButtons">
              <p>Please view in Desktop/Laptop</p>
              {/* <Button onClick={()=>setShowModal(false)}>OK</Button> */}
            </div>
          }
          backdrop={"static"}
        />
      )}
    </>
  );
}
const mapStateToProps = (state) => ({
  masterDataState: state.commonReducer,
});

export default connect(mapStateToProps, {
  downloadPersistencyLeads,
  deActivateLoader,
  activateLoader,
  getPersistancyLeadListing,
  downloadPersistencyLeadsWithIds,
})(LeadListingPerSistancyDatagrid);
