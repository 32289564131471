/**
 * @author Kiran Mohare
 * @email kiran.mohare@fulcrumdigital.com
 * @create date 2022-03-24 14:20:48
 * @modify date 03-06-2022 10:44:10
 * @desc [description]
 */

import React, { useState, useEffect } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DynamicForm from "f1-dynamic-form-react";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { t } from "i18next";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core/styles';
import { activateErrorMessageBar, getSubordinateList } from "../../../../redux/actions";
import { customValidators, persistancyLeadFilterForm } from "./persistancyLeadFilterForm";
import { USER_TYPE, ROLE_BDM, ROLE_FPM, ROLE_ABH } from "../../../../utils/config";
import moment from "moment";
import { de } from "date-fns/locale";
import { getDropdownDataBranch } from '../../../../utils/utils';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    drawerMobile: {
        display: "none",
        [theme.breakpoints.down('xs')]: {
            display: "block"
        },
        [theme.breakpoints.down('sm')]: {
            display: "block"
        }
    },
    desktopFilter: {
        [theme.breakpoints.down('xs')]: {
            display: "none"
        },
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }
    }
}));

const PersistancyLeadBacklogsFilter = ({
    anchor,filterState,setFilterState,setbacklogLeadReq, getFilteredBacklogList, getAllBacklogList, parent, handleClose, setSelectedFilter,
    setFilterDisabled}) => {
    const dispatch = useDispatch();
    const userData = JSON.parse(localStorage.getItem("persist:root"));
    const channelId = JSON.parse(userData.userReducer).profileInfo.channelid;
    const roleId = JSON.parse(userData.userReducer).profileInfo?.roleId;
    const history = useHistory();
    const userName = `${JSON.parse(userData.userReducer).profileInfo.firstName
        } ${" "}${JSON.parse(userData.userReducer).profileInfo.lastName}`;
    const {
        leadStatus,
        getCampaignNames
    } = useSelector((mainState) => mainState.leadFormReducer);// Activities,
    const userState = useSelector(nextState => nextState.userReducer);
    const UserId = userState?.profileInfo?.userId

    const [UserFullName, setUserFullName] = useState(userName);
    const { ProductDetails,Branches } = useSelector((next) => next.commonReducer)
    const { GetBDMByABH } = useSelector(reducer => reducer.commonReducer)

    const { persistancyActivity } = useSelector((nextReducer) => nextReducer.persistancyLeadListReducer);
    const [state, setState] = useState({
        current: { id: 1 },
        formData: persistancyLeadFilterForm,
        autoCompleteItems: {
            product: [],
            activityID: [],
            BranchId: []
        },
    });
    const meetingDateFormate = "YYYY-MM-DD";

    const dynamicRef = React.useRef();
    const [newUserList, setNewUserList] = useState([{
        key: UserId,
        label: UserFullName,
        value: UserId
    }])

    const getDropdownOFData = (optionsArr, id, label) => {
        let arr = [];
        if (optionsArr && optionsArr.length > 0) {
            arr = optionsArr.map((option) => {
                return { "id": `${option[id]}`, "label": `${option[label]}` }
            })
        }
        arr.shift({ "id": " ", "label": "select" })
        arr.unshift({ "id": " ", "label": "select" })
        return arr;
    }

    useEffect(() => {
        if (roleId !== ROLE_BDM && roleId !== ROLE_FPM) {
            getSub()
        }
        const autoCompleteItems = { ...state.autoCompleteItems };
        autoCompleteItems.product = getDropdownOFData(ProductDetails, "key", "label");
        autoCompleteItems.activityID = getDropdownData(
            persistancyActivity,
            "ActivityID",
            "ActivityName",
            "ActivityID"
        );
        const BranchesCopy = [...Branches];
        autoCompleteItems.BranchId = getDropdownDataBranch(BranchesCopy, 'key', 'label', 'value');
        const formData = initFormData(state.formData);
        setState({ formData: formData, autoCompleteItems: autoCompleteItems });
        if(
            channelId == 1 && roleId == ROLE_ABH ||
            channelId == 1 && roleId == ROLE_BDM ||
            channelId == 3 && roleId == ROLE_ABH ||
            channelId == 3 && roleId == ROLE_BDM
           ){
             persistancyLeadFilterForm[15].hide = false;
             persistancyLeadFilterForm[16].hide = false;
           } else {
           persistancyLeadFilterForm[15].hide = true;
           persistancyLeadFilterForm[16].hide = true;
           }
         persistancyLeadFilterForm[17].hide = true;
         persistancyLeadFilterForm[18].hide = true;
    }, []);
    useEffect(() => {
        console.log(UserFullName)
        // const formData = initFormData(state.formData);
        // setState({ formData: formData, autoCompleteItems: autoCompleteItems });
        setFilterState({
            UserName: UserFullName
        })
    }, [UserFullName]);

    const getSub = async () => {
        const formData = [...persistancyLeadFilterForm]
        dispatch(getSubordinateList({ userId: userState.profileInfo.userId })).then((response) => {
            const r = response.data.map(el => {
                return {
                    key: el.userID,
                    label: el.userName,
                    value: el.userID
                }
            });
            setNewUserList([...newUserList, ...r])
            formData[1].options = [...newUserList, ...r];

            if (GetBDMByABH !== 'all') {
                const res = response.data.filter((el) => {
                    if (GetBDMByABH == el.userID)
                        return true;
                });
                setUserFullName(res[0].userName)
                formData[0].hide = true;
                formData[1].hide = true;
                formData[2].hide = false;
            }
        })
    }
    const onSubmitHandler = (model) => {
        if (model.LeadName === "") {
            model.LeadName = null
        }
        if (model.CreatedDateTime === 0) {
            model.CreatedDateTime = null
        }
        if (model.MeetingDate === 0) {
            model.MeetingDate = null
        }
        if (model.MobileNo === "") {
            model.MobileNo = null
        }

        if (model.teamMember === 0 || model.teamMember === undefined) {
            model.teamMember = null
        }
        const status = model.persistancycurrentStatus?.map((val) => {
            return val.value;
        })
        model.currentStatus = status?.join(',');

        if (model.currentStatus === "") {
            model.currentStatus = null
        }
        const { CreatedDateEndTIme, LeadName, CreatedDateTime, CreatedDateStartTIme,
            currentStatus, product, activityID, MeetingDate, fromdate,
            todate, MobileNo, teamMember,BranchId,DueStartDate,DueEndDate,DueDateTime,persistancycurrentStatus } = model
        const persistancyActivityValue = [...persistancyActivity].some((el) => el.ActivityID === activityID)
        let meetingstart = null;
        let meetingend = null;
        let createdstart = null;
        let createdend = null;
        let duestart=null
        let dueend=null
        if (MeetingDate && MeetingDate !== null && MeetingDate !== 'duration' && MeetingDate !== undefined) {
            const lastdatearr = model?.MeetingDate?.split(',');
            meetingstart = lastdatearr[0] ? lastdatearr[0] : null;
            meetingend = lastdatearr[1] ? lastdatearr[1] : null;
        }
        if (CreatedDateTime && CreatedDateTime !== null && CreatedDateTime !== 'duration' && CreatedDateTime !== undefined) {
            const lastdatearr = model?.CreatedDateTime?.split(',');
            createdstart = lastdatearr[0] ? lastdatearr[0] : null;
            createdend = lastdatearr[1] ? lastdatearr[1] : null;
        }
        if (DueDateTime && DueDateTime !== null && DueDateTime !== 'duration' && DueDateTime !== undefined) {
            const lastdatearr = model.DueDateTime.split(',');
            duestart = lastdatearr[0] ? lastdatearr[0] : null;
            dueend = lastdatearr[1] ? lastdatearr[1] : null;
          }
        if (MeetingDate && MeetingDate === 'duration') {
            if (fromdate === null || todate === null) {
                dispatch(activateErrorMessageBar('Please Select Value For Both Meeting Start Date and End Date'))
                return false
            }
            meetingstart = model.fromdate;
            meetingend = model.todate
        }
        if (CreatedDateTime && CreatedDateTime === 'duration') {
            if (CreatedDateStartTIme === null || CreatedDateEndTIme === null) {
                dispatch(activateErrorMessageBar('Please Select Value For Both Created Start Date and End Date'))
                return false
            }
            createdstart = model.CreatedDateStartTIme;
            createdend = model.CreatedDateEndTIme
        }

        if (DueDateTime && DueDateTime === 'duration') {
            if (DueStartDate === null || DueEndDate === null) {
              dispatch(activateErrorMessageBar('Please Select Value For Both Created Start Date and End Date'))
              return false
            }
            duestart = model.DueStartDate;
            dueend = model.DueEndDate
        }

        model.fromdate = meetingstart;
        model.todate = meetingend;

        model.CreatedDateStartTIme = createdstart;
        model.CreatedDateEndTIme = createdend;

        model.DueStartDate = duestart;
        model.DueEndDate = dueend;
        if (CreatedDateTime !== null || LeadName !== null || product !== 0 || currentStatus !== null || activityID !== 0 || MeetingDate !== null || MobileNo !== null || teamMember !== null ||
             BranchId !==0 || DueStartDate !== null || DueEndDate !== null ) {
            const submitPayload = {
                LeadName: LeadName,
                CreatedDateTime: CreatedDateTime,
                CreatedDateStartTIme: CreatedDateStartTIme,
                CreatedDateEndTIme: CreatedDateEndTIme,
                MeetingOn: null,
                LastUpdateDateTime: null,
                currentStatus: currentStatus,
                product: product,
                activityID: activityID,
                MeetingDate: MeetingDate,
                fromdate: fromdate,
                todate: todate,
                MobileNo: MobileNo !== "" ? MobileNo : null,
                DueStartDate:DueStartDate,
                DueEndDate:DueEndDate,
                BranchId:BranchId,
                persistancycurrentStatus:persistancycurrentStatus?persistancycurrentStatus:null,
                "IsBacklog": true,
            }
            setFilterState({ ...filterState, submitPayload })
            setbacklogLeadReq({...submitPayload})
            getFilteredBacklogList(model, teamMember, persistancyActivityValue)
            handleClose();
        } else {
            dispatch(activateErrorMessageBar('Please select anyone to apply filter'))
        }
    };
    const onResetHandler = () => {
        let teamID = '';
        let payload = {
            ...filterState,
            Username: "",
            LeadID: null,
            LeadName: "",
            CurrentStatus: "",
            MeetingDate: "null",
            MeetingStartDate: null,
            CreatedDateTime: "null",
            currentStatus: [],
            MeetingEndDate: null,
            Createstartdate: null,
            Createenddate: null,
            productlabel: "",
            MobileNo: "",
            "IsBacklog": true,
            campaign: "null",
            activityIDlabel: "",
            DueDateTime:0,
            DueStartDate:null,
            DueEndDate:null,
            BranchId:'',
            BranchIdlabel:''
        }
        setFilterState(payload)
        const reqPayload = {
            Username: null,
            LeadID: null,
            LeadName: null,
            CurrentStatus: "1,2,3,4,5,6,7,8,9,10,14,25",
            MeetingStartDate: null,
            MeetingEndDate: null,
            Createstartdate: null,
            Createenddate: null,
            ActivityID: null,
            ProductID: null,
            MobileNo: null,
            DueStartDate:null,
            DueEndDate:null,
            BranchId:null,
            IsBacklog: true
        };
        setbacklogLeadReq(reqPayload)

        persistancyLeadFilterForm[1].hide = true
        persistancyLeadFilterForm[2].hide = false
        persistancyLeadFilterForm[17].hide = true
        persistancyLeadFilterForm[18].hide = true
        getAllBacklogList(payload, teamID,reqPayload)
        setFilterDisabled();
        handleClose();

    };
    const onChangeHandler = (e, key) => {

        if (key === 'LeadName') {
            setFilterState({
                ...filterState,
                LeadName: e.target.value
            })
        }
        if (key === 'MobileNumber') {
            setFilterState({
                ...filterState,
                MobileNo: e.target.value
            })
        }
        if (key === 'CreatedDateTime') {
            setFilterState({
                ...filterState,
                CreatedDateTime: e.target.value
            })
            if (e.target.value === 'duration') {
                persistancyLeadFilterForm[5].hide = false;
                persistancyLeadFilterForm[6].hide = false;
                setFilterState({
                    ...filterState,
                    CreatedDateTime: e.target.value,
                    CreatedDateStartTIme: moment(new Date()).format(meetingDateFormate),
                    CreatedDateEndTIme: moment(new Date()).format(meetingDateFormate),
                  });
            } else {
                persistancyLeadFilterForm[5].hide = true;
                persistancyLeadFilterForm[6].hide = true;
                setFilterState({
                    ...filterState,
                    CreatedDateTime: e.target.value,
                    CreatedDateStartTIme:"null",
                    CreatedDateEndTIme: "null",
                  });
            }
        }
        if (key === 'currentStatus') {
            setFilterState({
                ...filterState,
                currentStatus: e.target.value
            })
        }
        if (key === 'MeetingDate') {
            setFilterState({
                ...filterState,
                MeetingDate: e.target.value
            })
            if (e.target.value === 'duration') {
                persistancyLeadFilterForm[9].hide = false;
                persistancyLeadFilterForm[10].hide = false;
                setFilterState({
                    ...filterState,
                    MeetingDate: e.target.value,
                    fromdate: moment(new Date()).format(meetingDateFormate),
                    todate: moment(new Date()).format(meetingDateFormate),
                  })
            } else {
                persistancyLeadFilterForm[9].hide = true;
                persistancyLeadFilterForm[10].hide = true;
                setFilterState({
                    ...filterState,
                    MeetingDate: e.target.value,
                    fromdate: "null",
                    todate: "null",
                  })
            }
        }
        if (key === 'DueDateTime') {
            setFilterState({
              ...filterState,
              DueDateTime: e.target.value
            })
            if (e.target.value === 'duration') {
              persistancyLeadFilterForm[17].hide = false;
              persistancyLeadFilterForm[18].hide = false;
              setFilterState({
                ...filterState,
                DueDateTime: e.target.value,
                DueStartDate: moment(new Date()).format(meetingDateFormate),
                DueEndDate: moment(new Date()).format(meetingDateFormate),
              })
            } else {
              persistancyLeadFilterForm[17].hide = true;
              persistancyLeadFilterForm[18].hide = true;
              setFilterState({
                ...filterState,
                DueDateTime: e.target.value,
                DueStartDate: "null",
                DueEndDate: "null",
              })
            }
          }
        if (key === 'LastUpdateDateTime') {
            setFilterState({
                ...filterState,
                LastUpdateDateTime: e.target.value
            })
        }

        if (key === 'DueStartDate') {
            persistancyLeadFilterForm[18].min = e.target.value
        }

        if (key === 'DueStartDate') {
            setFilterState({
              ...filterState,
              DueStartDate: e.target.value
            })
        }

        if (key === 'DueEndDate') {
            setFilterState({
              ...filterState,
              DueEndDate: e.target.value
            })
        }

        if (key === 'product') {
            const branchNameValue = ProductDetails.filter((el) => el.key === parseInt(e.target.value))
            setFilterState({
                ...filterState,
                product: e.target.value,
                productlabel:branchNameValue[0]?.label
            })
        }
        // if (key === 'MeetingDate') {
        //     setFilterState({
        //         ...filterState,
        //         MeetingDate: e.target.value
        //     })
        //     if (e.target.value === 'duration') {
        //         persistancyLeadFilterForm[9].hide = false;
        //         persistancyLeadFilterForm[10].hide = false;
        //     } else {
        //         persistancyLeadFilterForm[9].hide = true;
        //         persistancyLeadFilterForm[10].hide = true;
        //     }
        // }
        if (key === 'CreatedDateStartTIme') {
            persistancyLeadFilterForm[6].min = e.target.value
        }
        if (key === 'fromdate') {
            persistancyLeadFilterForm[10].min = e.target.value
        }
        if (key === 'fromdate') {
            setFilterState({
                ...filterState,
                fromdate: e.target.value
            })
        }
        if (key === 'todate') {
            setFilterState({
                ...filterState,
                todate: e.target.value
            })
        }
        if (key === "userType") {
            const getUserType = e.target.value
            if (getUserType === "team") {
                persistancyLeadFilterForm[1].hide = false
                persistancyLeadFilterForm[2].hide = true
                // dispatch({
                //     type: "viewFor",
                //     payload: ""
                // })
                setSelectedFilter({
                    roleId: "ABH",
                    viewFor: "BDM"
                })
            } else {
                persistancyLeadFilterForm[1].hide = true
                persistancyLeadFilterForm[2].hide = false
                setSelectedFilter({
                    roleId: "ABH",
                    viewFor: "SELF"
                })
            }
        }
    };
    const getDropdownData = (optionsArr, key, label, value) => {
        let arr = [];
        if (optionsArr && optionsArr.length > 0) {
            arr = optionsArr.map((option) => {
                return { key: option[key], label: option[label], value: option[value] };
            });
        }
        arr.unshift({ key: "select", label: "Select", value: "" });
        return arr;
    };
    let persistancy_status;
  if(channelId == 1 && history?.location?.pathname === "/app/persistencylead/all"){
     persistancy_status = [26,27];
  }else{
    persistancy_status = [1, 2, 3, 4, 5, 7, 13, 14,25];
  }
    let filter_arr = [];
    if (leadStatus && leadStatus.length > 0) {
        filter_arr = leadStatus.filter((option) => {
            if (persistancy_status.includes(option.LeadStatusId)) {
                return option;
            }
        });
    }
    let LEAD_STATUS = []
    if (channelId == 1 && roleId == "ABH") {
        const restrictedArr = ["CONVERTED", "Auto Closed"]
        LEAD_STATUS = getDropdownData(

            filter_arr?.filter((option) => option.LeadStatus !== 'Converted'),
            "LeadStatus",
            "LeadStatus",
            "LeadStatusId"
        );
        LEAD_STATUS = LEAD_STATUS?.filter((item) => !restrictedArr.includes(item.key))
    } else {
        const restrictedArr = ["CONVERTED"]
        LEAD_STATUS = getDropdownData(

            filter_arr?.filter((option) => option.LeadStatus !== 'Converted'),
            "LeadStatus",
            "LeadStatus",
            "LeadStatusId"
        );
        LEAD_STATUS = LEAD_STATUS?.filter((item) => !restrictedArr.includes(item.key))

    }

    const CAMPAIGN_NAMES = getDropdownData(
        getCampaignNames,
        "CampaignNameID",
        "CampaignName",
        "CampaignNameID"
    )

    const onAutocompleteSelectHandler = (key, value, item) => {
        if (key === "activityID") {
            setFilterState({
                activityID: item.value
            })
        } else if (key === "BranchId") {
            setFilterState({
                BranchId: item.value
            })
          }
    }

    const initFormData = (formFields) => {
        const formData = [...formFields];
        formData[0].options = USER_TYPE
        formData[2].disabled = true;
        setFilterState({ ...filterState, UserName: UserFullName });
        formData[12].options = ProductDetails;
        formData[7].options = LEAD_STATUS;
        formData[13].options = CAMPAIGN_NAMES;
        return formData;
    };
    const classes = useStyles();

    return (
        <div className={anchor ? classes.drawerMobile : classes.desktopFilter}>
            <div className="filterBox">
                <h4 className="filterSec">
                    {" "}
                    <FilterAltIcon className="icnFilter" /> Filter By <CloseIcon className={classes.drawerMobile} style={{ float: 'right', cursor: 'pointer' }} onClick={handleClose} />
                </h4>
                <div className="fformBox">
                    <DynamicForm
                        key={1}
                        ref={dynamicRef}
                        className="dynamicForm leadFilters  leadFiltersMobile"
                        title={" "}
                        buttonSubmit={t("Apply Filter")}
                        defaultValues={filterState}
                        customValidators={customValidators}
                        model={state.formData}
                        onSubmit={(model) => onSubmitHandler(model)}
                        disableSubmitOnInValid={false}
                        design="bootrstap"
                        materialOutline={false}
                        onChange={(event, key) => onChangeHandler(event, key)}
                        onAutocompleteSelect={onAutocompleteSelectHandler}
                        autoCompleteItems={state.autoCompleteItems}
                        showAstersikOnRequiredFields={false}

                        extaraActionFormButton={
                            <span className="reset" onClick={onResetHandler} ><RestartAltIcon /> Reset</span>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default React.memo(PersistancyLeadBacklogsFilter);
