import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@mui/material/Typography";
import "./lisiting.scss";
import moment from "moment";
import Box from "@mui/material/Box";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Fab from "@mui/material/Fab";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import WifiCalling3Icon from "@mui/icons-material/WifiCalling3";
import VideocamIcon from "@mui/icons-material/Videocam";
import BarChartIcon from "@mui/icons-material/BarChart";
import SmsIcon from "@mui/icons-material/Sms";
import MailIcon from "@mui/icons-material/Mail";
import NavigationIcon from "@mui/icons-material/Navigation";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@mui/material/Checkbox";
import { useHistory } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ListItemText from "@mui/material/ListItemText";
import { useDispatch, useSelector } from "react-redux"
import { setSelectedLeadId } from "../../redux-reducer/reducer/leadListReducer";
import { activateErrorMessageBar, setHypercollateralFlag } from "../../redux/actions";
import { ROLE_ABH, ROLE_BDM } from "../../utils/config";
import { getMobileOS, setSession } from "../../utils/utils";
import { SIMPLIFY_ANDROID_APP_URL, HYPER_COLLATRAL_TOKEN, HYPERCOLLATERAL_PAGE } from "../../api/baseURL";

function Listing({
  data,
  toggleDrawer,
  loadMoreRecord,
  TotalnoofRows,
  NoOfRecordperPage,
  roleId,
  toggleReAssignLeadDrawer
}) {
  const history = useHistory()
  const dispatch = useDispatch();
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [leadList, setLeadList] = useState([]);
  const [isSelectedLeadValidToReAssign, setIsSelectedLeadValidToReAssign] = useState(false);
  const [getSelectTedMobileNumber, setGetSelectTedMobileNumber] = useState({ mobileNumber: [] })
  const userState = useSelector(state => state.userReducer)
  const channelID = userState.profileInfo.channelid
  useEffect(() => {
    setLeadList(data);
    if (isCheckAll) {
      const checkedValues = [...isCheck]
      const extValues = data.filter((ele) => !checkedValues.includes(ele.LeadId)).map(element => element.LeadId)

      const extendedCheckedValues = [...isCheck, ...extValues]
      setIsCheck(extendedCheckedValues)
    }
  }, [data]);

  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(leadList.map(li => li.LeadId));
    const details = []
    leadList.forEach((item) => {
      details.push({
        ...item, MobileNo: `+91${item.MobileNo}`
      })
    })
    setGetSelectTedMobileNumber({ mobileNumber: details?.map(li => li.MobileNo) })
    if (isCheckAll) {
      setIsCheck([]);
      setGetSelectTedMobileNumber({ mobileNumber: [] })
    }
  };

  const handleClick = (e, mobileNo) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, parseInt(id)]);
    if (!isCheckAll) {
      if (!getSelectTedMobileNumber.mobileNumber.includes(`+91${mobileNo}`)) {
        setGetSelectTedMobileNumber({ mobileNumber: [...getSelectTedMobileNumber.mobileNumber, `+91${mobileNo}`] })
      } else {
        setGetSelectTedMobileNumber({ mobileNumber: getSelectTedMobileNumber.mobileNumber.filter(li => li !== `+91${mobileNo}`) })
      }
    } else {
      setGetSelectTedMobileNumber({ mobileNumber: getSelectTedMobileNumber.mobileNumber.filter(li => li !== `+91${mobileNo}`) })
    }
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== parseInt(id)));
    } else {
      setGetSelectTedMobileNumber({ mobileNumber: [...getSelectTedMobileNumber.mobileNumber, `+91${mobileNo}`] })
    }
  };

  useEffect(() => {
    dispatch(setSelectedLeadId(isCheck))
    const leadStatusID = [2, 3, 4, 7, 1]
    const leadCheckedList = leadList.filter(x => isCheck.includes(x.LeadId))
    const flag = leadCheckedList.filter(ele => !leadStatusID.includes(ele.LeadStatusId)).length <= 0
    setIsSelectedLeadValidToReAssign(flag)
  }, [isCheck])

  const goToLeadPage = (id, statusId) => {
    if (statusId === 11) {
      history.push(`/app/lead/update/${id}`);
    } else {
      history.push(`/app/lead/detail/${id}`);
    }
  };
  const leadlistlable = {
    "wordBreak": "break-all",
    "marginRight": "17px",
  };

  const getIntials = (name) => {
    if (name != null) {
      return name.slice(0, 1).toUpperCase();
    } else {
      return "";
    }
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(3),
      textAlign: "left",
      color: theme.palette.text.secondary,
    },
    activityLogo: {
      borderRadius: 10,
      textAlign: "center",
    },
    heading: {
      padding: "10px 20px 20px 10px",
    },
    subHeading: {
      fontSize: 16,
      fontWeight: "400",
      padding: "5px 10px !important",
    },
    subHeadingBold: {
      fontWeight: "bold",
    },
  }));
  const classes = useStyles();

  const handleAudioCall = (MobileNo, e) => {
    e.stopPropagation()
    window.open(`tel:${MobileNo}`)
  }

  const handleSMS = (MobileNo, e) => {
    e.stopPropagation()
    window.open(`sms:${MobileNo}`)
  }

  const handleVideoCall = (MobileNo, e) => {
    e.stopPropagation()
    window.open(`https://wa.me/${MobileNo}?text=Hello`, '_blank');
  }

  const handleNavigate = (id, e) => {
    e.stopPropagation()
  }

  const handleEmail = (Email, e) => {
    e.stopPropagation()
    window.open(`mailto:${Email}`)
  }

  const goToPage = (e) => {
    e.stopPropagation()
  }
  const handleCollateral = (Lead, e) => {
    e.stopPropagation()
    const userData = userState.profileInfo;
    const payload = {
      "UserID": userData.userId,
      "LeadID": Lead.LeadId,
      "ChannelID": userData.channelid
    }
    dispatch(setHypercollateralFlag(payload));
    dispatch({
      type: "setCollateral",
      payload: true
    })
    setSession("setCollateral", true);
    const getDeviceType = getMobileOS();
    const edmChannel = userData.edmChannel.replace(/ /g,'')
    const fullname = Lead.FirstName + " " + Lead.LastName; // + (Lead.MiddleName != null ? Lead.MiddleName : '') + " "
    const matchQuery = `${"FullName="}${fullname}${"&Email="}${Lead.Email}${"&Mobile="}${Lead.MobileNo}${"&ExternalLeadNumber="}${Lead.LeadId}${"&Channel="}${edmChannel}`;
    let redirectUrl = `${HYPERCOLLATERAL_PAGE}${matchQuery}`;

    // if (getDeviceType === "Android") {
    //   redirectUrl = `${HYPERCOLLATERAL_PAGE}${matchQuery}`;
    // } else {
    //   redirectUrl = `${HYPERCOLLATERAL_PAGE}${matchQuery}`;
    // }de
    Object.assign(document.createElement("a"), {
      target: "_blank",
      rel: "noopener noreferrer",
      href: redirectUrl,
    }).click();
  }

  const onSelectChange = (e) => {
    e.stopPropagation()
    if (e.target.value === 'ReAssign Lead') {
      // toggleReAssignLeadDrawer('right', true)
      if (isCheck.length > 0) {
        if (isSelectedLeadValidToReAssign) {
          history.push('/app/lead/reassignLead')
        } else {
          dispatch(activateErrorMessageBar('Only Open Leads Are Valid to Reassign'))
        }
      } else {
        dispatch(activateErrorMessageBar('Please Select a Lead to Reassign'))
      }
    } else if (
      e.target.value === "Send SMS"
    ) {
      if (getSelectTedMobileNumber.mobileNumber && getSelectTedMobileNumber.mobileNumber.length > 0) {
        const mobileNo = getSelectTedMobileNumber.mobileNumber.toString().split(',').join(';')
        window.open(`sms:${mobileNo}`)
      } else {
        dispatch(activateErrorMessageBar('Please select any one Lead'))
      }
    } else if (e.target.value === "Send Email") {
      if (isCheck.length > 0) {
        const selectedMailList = leadList.filter(x => isCheck.includes(x.LeadId)).map((ele => ele.Email)).toString().split(',').join(';')
        console.log(selectedMailList, 'selectedmail')
        window.open(`mailto:${selectedMailList}`)
      } else {
        dispatch(activateErrorMessageBar('Please select any one Lead'))
      }
    }

  }
  const PriorityColor = {
    Hot: '#DE4039',
    Warm: '#EF7A22',
    Cold: '#0093DE',
    High: '#DE4039',
    Medium: '#EF7A22',
    Low: '#0093DE'
  };

  return (
    <>
      <Grid item xs={12} md={9} className="leftCol">
        <div className="topMenus">
          <button
            className="addItem"
            onClick={() => history.push("/app/lead/create")}
          >
            +
          </button>
          <Box
            className="editBtn leadFilterBox"
            sx={{ display: { xs: "flex", md: "none" } }}
            onClick={toggleDrawer("bottom", true)}
          ><span className="icnFilterBox">
              <Fab color="primary" aria-label="add" sx={{ float: "right" }}>
                <FilterAltIcon />
              </Fab>
            </span>
          </Box>
          <Grid container spacing={3} className="topDummy">
            <Grid item xs={8} md={6}>

              <div className="selectBox">
                <FormControlLabel
                  label="Select All"
                  control={
                    <Checkbox
                      onChange={() =>
                        handleSelectAll()
                      }
                      isChecked={isCheckAll}
                    />
                  }
                />
              </div>
              <div className="smsBox">
                {" "}
                <select value="" onChange={(e) => onSelectChange(e)}>
                  <option value="">Select</option>
                  <option value="Send SMS" disabled={getSelectTedMobileNumber.mobileNumber.length > 2}>Send SMS</option>
                  {userState.profileInfo.channelid === 3 && roleId === ROLE_BDM && <option value="Send Email" >Send Email</option>}
                  {roleId === ROLE_ABH && <option value="ReAssign Lead"  >ReAssign Lead</option>}
                </select>{" "}
              </div>
            </Grid>
            <Grid item xs={4} md={6}>
              <div className="rightPanel">
                <span>
                  {" "}
                  {NoOfRecordperPage < TotalnoofRows
                    ? NoOfRecordperPage
                    : TotalnoofRows}{" "}
                </span>
                /
                <span>
                  {TotalnoofRows > 1
                    ? `${TotalnoofRows}${" "}${"Results"}`
                    : `${TotalnoofRows}${" "}${"Result"}`}
                </span>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="leadList">
          {leadList &&
            leadList.length > 0 &&
            leadList
              .map((lead, index) => (

                <div className="listingCard leadHeightMobile" key={lead.LeadId}>
                  <Grid container textAlign="left">
                    <Grid item xs={12}>
                      <Paper className={classes.paper}>
                        <Grid
                          container className="mobileResponsive"
                          style={{ position: "relative" }}
                          onClick={() =>
                            goToLeadPage(lead.LeadId || lead.LmsleadGuid, lead.LeadStatusId)
                          }
                        >
                          <div className="mobIcons">
                            <span className="icn" onClick={(e) => handleAudioCall(lead.MobileNo, e)}>
                              <WifiCalling3Icon /> <em>Audio Call</em>
                            </span>
                            <span className="icn" onClick={(e) => handleVideoCall(lead.MobileNo, e)}>
                              <VideocamIcon />
                              <em>Video Call</em>
                            </span>
                            <span className="icn" onClick={(e) => handleSMS(lead.MobileNo, e)}>
                              <SmsIcon />
                              <em>SMS</em>
                            </span>
                            <span className="icn" onClick={(e) => handleEmail(lead.Email, e)}>
                              <MailIcon />
                              <em>Email</em>
                            </span>
                            {
                              lead.Location &&
                              <span className="icn" onClick={(e) => handleNavigate(lead.Location, e)}>
                                <NavigationIcon />
                                <em>Navigate</em>
                              </span>
                            }
                          </div>
                          <Grid
                            item
                            xs={2}
                            md={1}
                            className="checkBoxCol"
                            alignItems="center"
                            style={{ justifyContent: "space-around" }}
                          >
                            <Checkbox
                              id={lead.LeadId}
                              checked={isCheck.includes(lead.LeadId)}
                              onClick={event => event.stopPropagation()}
                              onChange={(e) => {
                                handleClick(e, lead.MobileNo)
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            md={1}
                            className={`gridLogo ${classes.activityLogo}`}
                          >
                            <Typography variant="h5" component="h2">
                              {getIntials(lead.FirstName)}{" "}
                              {getIntials(lead.LastName)}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={10}>
                            <Grid container spacing={3} className="cardTop">
                              <Grid item xs={12} md={6} className="mobileTitle">
                                <ListItemText className="listTitle"
                                  disableTypography
                                  primary={
                                    <Typography
                                      variant="h5"
                                      component="h2"
                                      className={classes.heading}
                                    >
                                      <b className="mobHeader"></b>
                                      <span
                                        className="branchName"
                                        style={leadlistlable}
                                      >
                                        {" "}
                                        {/* {lead.IsSpotLeadClosure == 1 ?
                                          <></> : */}
                                          <>{lead.SalutationName || lead.Salutation}</>  {lead.FirstName}{" "}
                                        {lead.LastName}
                                      </span>
                                    </Typography>
                                  }
                                />
                                {channelID !== 79 &&
                                  <>
                                    <span className="hotClass">
                                      {lead.IsSpotLeadClosure == 1 ?
                                        <> </> :
                                        <>
                                          <span className="icnNo">
                                            <BarChartIcon style={{ color: PriorityColor[lead.PriorityName] }} />
                                          </span>
                                          <span
                                            className="subTitle"
                                            style={{ color: PriorityColor[lead.PriorityName] }}
                                          >
                                            {lead.PriorityName}{lead.PriorityAnalyticStatusName ? `- ${lead.PriorityAnalyticStatusName}` : " "} Lead
                                          </span>
                                        </>
                                      }
                                    </span>
                                  </>
                                }

                              </Grid>
                              <Grid item xs={12} md={6} className="buttonBox">
                                <span className="icn" onClick={(e) => handleAudioCall(lead.MobileNo, e)}>
                                  <WifiCalling3Icon /> <em>Audio Call</em>
                                </span>
                                <span className="icn" onClick={(e) => handleVideoCall(lead.MobileNo, e)}>
                                  <VideocamIcon />
                                  <em>Video Call</em>
                                </span>
                                <span className="icn" onClick={(e) => handleSMS(lead.MobileNo, e)}>
                                  <SmsIcon />
                                  <em>SMS</em>
                                </span>
                                <span className="icn" onClick={(e) => handleEmail(lead.Email, e)}>
                                  <MailIcon />
                                  <em>Email</em>
                                </span>
                                {lead.Location &&
                                  <span className="icn" onClick={(e) => handleNavigate(lead.Location, e)}>
                                    <NavigationIcon />
                                    <em>Navigate</em>
                                  </span>
                                }

                                {/* <span onClick={()=>onRescheduleActivityHandler(item)} className="icnUpdate" title="Reschedule"><ScheduleIcon/></span>
                                      <span onClick={()=>onCompleteActivityHandler(item)} className="icn" title="Complete"><CheckCircleIcon/></span>
                                      <span onClick={()=>onCancelActivityHandler(item)} className="icn" title="Cancel"><CancelIcon/></span> */}
                                <span
                                  className="icn rightIcn"
                                >
                                  <ArrowRightAltIcon />
                                </span>
                              </Grid>
                            </Grid>
                            <Grid container >
                              <Grid
                                xs={12}
                                md={12}
                                className="collateral_link"
                              >
                                {(lead.LeadStatus !== "Interested" && lead.LeadStatus !== "Dropped" && lead.ChannelID == 1) ?
                                  <label onClick={(e) => handleCollateral(lead, e)} className="collateral"><a href="#">Share Hyper-Personalized Collateral</a></label> :
                                   <label className="collateral">Share Hyper-Personalized Collateral</label>
                                }
                            </Grid>

                          </Grid>
                          <Grid
                            container
                            spacing={3}
                            sx={{ mt: 1 }}
                            className="labelGroup"
                          >
                            {roleId === "ABH" && <Grid
                              item
                              xs={12}
                              md={3}
                              className={classes.subHeading}
                            >
                              <label>BDM</label>
                              <br />
                              <span className={classes.subHeadingBold} onClick={goToPage}>
                                <p>
                                  {lead.LeadOwner}
                                </p>
                              </span>{" "}
                              { }
                            </Grid>
                            }
                            <Grid
                              item
                              xs={12}
                              md={3}
                              className={classes.subHeading}
                            >
                              <label>Created Date</label>
                              <br />
                              <span className={classes.subHeadingBold}>
                                <p className="variantContent">
                                  {lead.CreatedDate
                                    ? moment(lead.CreatedDate).format(
                                      "DD MMM YYYY"
                                    )
                                    : "N/A"}{" "}
                                  <span className="content">
                                    {lead.CreatedDate
                                      ? moment(lead.CreatedDate).format("h:mma")
                                      : " "}
                                  </span>
                                </p>
                              </span>{" "}
                              { }
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={3}
                              className={classes.subHeading}
                            >
                              <label>Meeting Date</label>
                              <br />

                              {lead.IsSpotLeadClosure == 1 ?
                                <>N/A</> :
                                <>
                                  <span className={classes.subHeadingBold}>
                                    {" "}
                                    <p className="variantContent">
                                      {lead.MeetingStartDateTime
                                        ? moment(lead.MeetingStartDateTime).format(
                                          "DD MMM YYYY"
                                        )
                                        : "N/A"}{" "}
                                      <span className="content">
                                        {lead.MeetingStartDateTime
                                          ? moment(
                                            lead.MeetingStartDateTime
                                          ).format("h:mma")
                                          : " "}
                                      </span>
                                    </p>
                                  </span>
                                </>
                              }


                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={3}
                              className={classes.subHeading}
                            >
                              <label>Current Status</label>
                              <br />
                              <span
                                className={`consumer ${classes.subHeadingBold}`}
                              >
                                {lead.LeadStatus}
                              </span>
                            </Grid>
                            {/* history?.location?.pathname !=="/app/recruitmentlead/all" */}
                            {lead.IsSpotLeadClosure == 1 ?
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  md={3}
                                  className={classes.subHeading}
                                >
                                  <label>Product</label>
                                  <br />
                                  <span className={classes.subHeadingBold}>
                                    N/A
                                  </span>
                                </Grid>
                              </> :
                              <>
                                {
                                  lead.ProductName && <Grid
                                    item
                                    xs={12}
                                    md={3}
                                    className={classes.subHeading}
                                  >
                                    <label>Product</label>
                                    <br />
                                    <span className={classes.subHeadingBold}>
                                      {lead.ProductName}
                                    </span>
                                  </Grid>
                                }
                              </>
                            }


                            {/* history?.location?.pathname !=="/app/recruitmentlead/all" */}
                            {/* {(lead.ExpectedPremium || lead.ExpectedPremiumAmount) &&  */}
                            <Grid
                              item
                              xs={12}
                              md={3}
                              className={`premium ${classes.subHeading}`}
                            >
                              <label>Expected Premium</label>
                              <br />

                              {lead.IsSpotLeadClosure == 1 ?
                                <span className={classes.subHeadingBold}>
                                  N/A</span> :
                                <>
                                  {
                                    <span className={classes.subHeadingBold}>
                                      {(lead.ExpectedPremium != null || lead.ExpectedPremiumAmount != null) && <CurrencyRupeeIcon fontSize="small" />}
                                      {/* {(userState.profileInfo.channelid !== 13 && userState.profileInfo.channelid !== 79) && parseInt(lead.ExpectedPremium).toLocaleString()}{" "}
                                        {(userState.profileInfo.channelid === 13 || userState.profileInfo.channelid === 79) && parseInt(lead.ExpectedPremiumAmount)}{" "} */}

                                      {(userState.profileInfo.channelid !== 13 && userState.profileInfo.channelid !== 79) ? lead.ExpectedPremium != null ? parseInt(lead.ExpectedPremium).toLocaleString() : "N/A" : " "}
                                      {(userState.profileInfo.channelid === 13 || userState.profileInfo.channelid === 79) ? lead.ExpectedPremiumAmount != null ? parseInt(lead.ExpectedPremiumAmount) : "N/A" : " "}
                                    </span>
                                  }
                                </>
                              }

                            </Grid>
                            {/* } */}
                          </Grid>
                        </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                  </Grid>
    </div >
              ))
}
        </div >

{/* <Pagination count={Math.ceil(TotalnoofRows / NoOfRecordperPage)} shape="rounded" defaultPage={1} siblingCount={1}
              boundaryCount={1}
              onChange={handlePageChange}
            /> */}

{/* <button className="btn loadMore" onClick={loadMoreRecord}>Load More</button> */ }
{
  NoOfRecordperPage <= TotalnoofRows ? (
    <button className="btn loadMore" onClick={loadMoreRecord}>
      Load More
    </button>
  ) : (
  <div className="noMoreDiv">No more records to load</div>
)
}
      </Grid >
    </>
  );
}

export default Listing;
